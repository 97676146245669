import { mesDataService } from "@/services/mesDataService";

const cmLog = {
  namespaced: true,
  state: {
  
  },
  getters: {},

  mutations: {
   
 
  },
  actions: {
    async create({ dispatch }, payload) {
      console.log(payload);
      try {
         await mesDataService.cmFile.create(payload);

      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },
    
   
  
  },
  
};

export { cmLog };
