
import http from './http-common'
import httpUpload from './http-upload'
import axios from 'axios'

const mesDataService = {
    company: {
        create: (data) => http.post('/company', data),
        update: (id, data) => http.post(`/company/${id}`, data),
        list: () => http.get('/company'),
        destroy: (id) => http.delete(`/company/${id}`)
    },

    department: {
        create: (data) => http.post('/department', data),
        update: (id, data) => http.post(`/department/${id}`, data),
        list: () => http.get('/department'),
        destroy: (id) => http.delete(`/department/${id}`)
    },

    division: {
        create: (data) => http.post('/division', data),
        update: (id, data) => http.post(`/division/${id}`, data),
        list: () => http.get('/division'),
        destroy: (id) => http.delete(`/division/${id}`)
    },

    project: {
        create: (data) => http.post('/project', data),
        update: (id, data) => http.post(`/project/${id}`, data),
        list: () => http.get('/project'),
        destroy: (id) => http.delete(`/project/${id}`),
        findByDepartmentId: (id) => http.get(`/project/findByDepartmentId?departmentId=${id}`),
        findOne: (id) => http.get(`/project/findOne/${id}`),
        findByPk: (projectId) => http.get(`/project/findByPk?projectId=${projectId}`)
    },

    shift: {
        create: (data) => http.post('/shift', data),
        update: (id, data) => http.post(`/shift/${id}`, data),
        list: () => http.get('/shift'),
        destroy: (id) => http.delete(`/shift/${id}`)
    },

    prefix: {
        create: (data) => http.post('/prefix', data),
        update: (id, data) => http.post(`/prefix/${id}`, data),
        list: () => http.get('/prefix'),
        destroy: (id) => http.delete(`/prefix/${id}`)
    },

    executor: {
        create: (data) => http.post('/executor', data),
        update: (id, data) => http.post(`/executor/${id}`, data),
        list: () => http.get('/executor'),
        destroy: (id) => http.delete(`/executor/${id}`),
        findByDepartment: (id) => http.get(`/executor/findByDepartment/${id}`)
    },
    vender: {
        create: (data) => http.post('/vender', data),
        update: (id, data) => http.post(`/vender/${id}`, data),
        list: () => http.get('/vender'),
        destroy: (id) => http.delete(`/vender/${id}`),
    },

    brand: {
        create: (data) => http.post('/brand', data),
        update: (id, data) => http.post(`/brand/${id}`, data),
        list: () => http.get('/brand'),
        destroy: (id) => http.delete(`/brand/${id}`)
    },

    process: {
        create: (data) => http.post('/process', data),
        update: (id, data) => http.post(`/process/${id}`, data),
        list: () => http.get('/process'),
        destroy: (id) => http.delete(`/process/${id}`)
    },

    machineType: {
        create: (data) => http.post('/mct', data),
        update: (id, data) => http.post(`/mct/${id}`, data),
        list: () => http.get('/mct'),
        destroy: (id) => http.delete(`/mct/${id}`)
    },

    meter: {
        create: (data) => http.post('/meter', data),
        update: (id, data) => http.post(`/meter/${id}`, data),
        list: () => http.get('/meter'),
        destroy: (id) => http.delete(`/meter/${id}`)
    },

    energy: {
        create: (data) => http.post('/energy', data),
        update: (id, data) => http.post(`/energy/${id}`, data),
        list: () => http.get('/energy'),
        destroy: (id) => http.delete(`/energy/${id}`)
    },

    machine: {
        create: (data) => http.post('/machine', data),
        update: (id, data) => http.post(`/machine/${id}`, data),
        list: () => http.get('/machine'),
        destroy: (id) => http.delete(`/machine/${id}`),
        machineList: (projectId) => http.get(`/machine/machineList/${projectId}`),
        findByDepartment: (id) => http.get(`/machine/findByDepartment/${id}`),
        listSuite: () => http.get('/machine/listSuite'),
        listByProjectArray: (projectId) => http.get(`/machine/listByProjectArray?projectId=${projectId}`),
        findByPk: (id) => http.get(`/machine/findByPk?machineId=${id}`)
    },

    dumpSpot: {
        create: (data) => http.post('/dumpSpot', data),
        update: (id, data) => http.post(`/dumpSpot/${id}`, data),
        list: () => http.get('/dumpSpot'),
        destroy: (id) => http.delete(`/dumpSpot/${id}`)
    },

    member: {
        get: (id) => http.get(`/member/${id}`),
        getByEmail: (email) => http.get(`/member/getByEmail?email=${email}`),
        create: (data) => http.post('/member', data),
        update: (id, data) => http.post(`/member/${id}`, data),
        list: () => http.get('/member'),
        destroy: (id) => http.delete(`/member/${id}`)
    },

    projectWork: {
        create: (data) => http.post('/projectWork', data),
        destroy: (id) => http.delete(`/projectWork/${id}`),
        findByProjectId: (projectId) => http.get(`/projectWork/${projectId}`),
        list: () => http.get('/projectWork')

    },

    projectRoles: {
        create: (data) => http.post('/projectRoles', data),
        destroy: (id) => http.delete(`/projectRoles/${id}`),
        findByMemberId: (memberId) => http.get(`/projectRoles/${memberId}`),
        list: () => http.get('/projectRoles')

    },

    logDelete: {
        create: (data) => http.post('/logdelete', data)
    },

    workSheet: {
        create: (data) => http.post('/workSheet', data),
        update: (id, data) => http.post(`/workSheet/${id}`, data),
        list: () => http.get('/workSheet'),
        destroy: (id) => http.delete(`/workSheet/${id}`)
    },

    truckScale: {
        create: (data) => http.post('/truckScale', data),
        update: (id, data) => http.post(`/truckScale/${id}`, data),
        list: () => http.get('/truckScale'),
        destroy: (id) => http.delete(`/truckScale/${id}`)
    },

    haulingMajor: {
        create: (data) => http.post('/haulingMajor', data),
        update: (id, data) => http.post(`/haulingMajor/${id}`, data),
        listBetweenDate: (startDate, endDate) => http.get(`/haulingMajor/listBetweenDate/${startDate}/${endDate}`),
        get: (workingDate, projectId) => http.get(`/haulingMajor/${workingDate}/${projectId}`),
        findByPk: (id) => http.post(`/haulingMajor/findByPk/${id}`),
        destroy: (id) => http.delete(`/haulingMajor/${id}`),
        listHaulingProdDataByWorkItem: (startDate, endDate, projectId, workItemId) => http.get(`/haulingMajor/listHaulingProdDataByWorkItem/${startDate}/${endDate}/${projectId}/${workItemId}`),
        listHaulingProdData: (startDate, endDate, projectId) => http.get(`/haulingMajor/listHaulingProdData/${startDate}/${endDate}/${projectId}`),
        listMeterShift: (workingDate,shiftId,machineId) => http.get(`/haulingMajor/listMeterShift/${workingDate}/${shiftId}/${machineId}`),
    },

    hauling: {
        create: (data) => http.post('/hauling', data),
        update: (data) => http.put('/hauling', data),
        destroy: (id) => http.delete(`/hauling/${id}`),
        destroyTrip: (id) => http.delete(`/hauling/destroyTrip/${id}`),
        list: (haulingMajorId) => http.get(`/hauling/${haulingMajorId}`),
        haulingLoadingSpot: (haulingMajorId) => http.get(`/hauling/haulingLoadingSpot/${haulingMajorId}`),
        haulingTrip: (haulingMajorId) => http.get(`/hauling/haulingTrip/${haulingMajorId}`),
        haulingTripUsed: (haulingMajorId) => http.get(`/hauling/haulingTripUsed/${haulingMajorId}`),
        haulingGroupExcavator: (startDate, endDate, projectId) => http.get(`/hauling/haulingGroupExcavator/${startDate}/${endDate}/${projectId}`),
        haulingDayForLoading: (workingDate, projectId, shiftId, machineId, workItemId) => http.get(`/hauling/haulingDayForLoading/${workingDate}/${projectId}/${shiftId}/${machineId}/${workItemId}`),

    },

    workItem: {
        create: (data) => http.post('/workItem', data),
        update: (id, data) => http.post(`/workItem/${id}`, data),
        list: () => http.get('/workItem'),
        destroy: (id) => http.delete(`/workItem/${id}`)
    },

    loadingSpot: {
        create: (data) => http.post('/loadingSpot', data),
        update: (id, data) => http.post(`/loadingSpot/${id}`, data),
        list: () => http.get('/loadingSpot'),
        destroy: (id) => http.delete(`/loadingSpot/${id}`)
    },

    loadingMajor: {
        create: (data) => http.post('/loadingMajor', data),
        update: (id, data) => http.post(`/loadingMajor/${id}`, data),
        get: (workingDate, projectId) => http.get(`/loadingMajor/${workingDate}/${projectId}`),
        findByPk: (id) => http.post(`/loadingMajor/findByPk/${id}`),
        destroy: (id) => http.delete(`/loadingMajor/${id}`),
        list: (startDate, endDate, projectId) => http.get(`/loadingMajor/list/${startDate}/${endDate}/${projectId}`),

    },

    loading: {
        create: (data) => http.post('/loading', data),
        update: (data) => http.put('/loading', data),
        destroy: (loadingMajorId) => http.delete(`/loading/${loadingMajorId}`),
        destroyTrip: (id) => http.delete(`/loading/destroyTrip/${id}`),
        list: (loadingMajorId) => http.get(`/loading/${loadingMajorId}`),
        loadingTrip: (loadingMajorId) => http.get(`/loading/loadingTrip/${loadingMajorId}`),
        loadingMapHauling: (startDate, endDate, projectId) => http.get(`/loading/loadingMapHauling/${startDate}/${endDate}/${projectId}`),
        findByPk: (id) => http.post(`/loading/findByPk/${id}`),
        findByHaulingId: (id) => http.post(`/loading/findByHaulingId/${id}`),
    },

    machineDaily: {
        create: (data) => http.post('/machineDaily', data),
        update: (data) => http.put('/machineDaily', data),
        get: (workingDate, projectId) => http.get(`/machineDaily/${workingDate}/${projectId}`),
        listByYearMonth: (year, month, projectId) => http.get(`/machineDaily/listByYearMonth/${year}/${month}/${projectId}`),
    },

    energyUsage: {
        create: (data) => http.post('/energyUsage', data),
        update: (id, data) => http.post(`/energyUsage/${id}`, data),
        destroy: (id) => http.delete(`/energyUsage/${id}`),
        listBetweenDate: (startDate, endDate, projectId) => http.get(`/energyUsage/listBetweenDate/${startDate}/${endDate}/${projectId}`),
        listByMachineId: (machineId) => http.get(`/energyUsage/listByMachineId/${machineId}`),
        getMaxMrno: (startDate, endDate) => http.get(`/energyUsage/getMaxMrno/${startDate}/${endDate}`),
        listStatus: (startDate, endDate, projectId) => http.get(`/energyUsage/listStatus/${startDate}/${endDate}/${projectId}`),
    },

    supportJobType: {
        create: (data) => http.post('/supportJobType', data),
        update: (id, data) => http.post(`/supportJobType/${id}`, data),
        list: () => http.get('/supportJobtype'),
        destroy: (id) => http.delete(`/supportJobType/${id}`)
    },

    supportMachineMajor: {
        create: (data) => http.post('/supportMachineMajor', data),
        update: (id, data) => http.post(`/supportMachineMajor/${id}`, data),
        get: (workingDate, projectId) => http.get(`/supportMachineMajor/${workingDate}/${projectId}`),
        destroy: (id) => http.delete(`/supportMachineMajor/${id}`),
        listByYearMonth: (startDate, endDate, projectId) => http.get(`/supportMachineMajor/listByYearMonth/${startDate}/${endDate}/${projectId}`),
        list: (startDate, endDate, projectId) => http.get(`/supportMachineMajor/list/${startDate}/${endDate}/${projectId}`),
    },

    supportMachine: {
        create: (data) => http.post('/supportMachine', data),
        update: (id, data) => http.post(`/supportMachine/${id}`, data),
        get: (supportMachineMajorId) => http.get(`/supportMachine/${supportMachineMajorId}`),
        destroy: (id) => http.delete(`/supportMachine/${id}`)
    },

    dashboard: {
        getHaulingTimesTonsOperator: (year, projectId) => http.get(`/dashboard/getHaulingTimesTonsOperator/${year}/${projectId}`),
        getBubbleOperator: (year, projectId) => http.get(`/dashboard/getBubbleOperator/${year}/${projectId}`),

        haulingProductionPerMonth: (startDate, endDate, projectId, loadingSpot, dumpSpot) => http.get(`/dashboard/haulingProductionPerMonth/${startDate}/${endDate}/${projectId}/${loadingSpot}/${dumpSpot}`),
        haulingProductionPerYear: (year, projectId, loadingSpot, dumpSpot) => http.get(`/dashboard/haulingProductionPerYear/${year}/${projectId}/${loadingSpot}/${dumpSpot}`),
        haulingTruck: (startDate, endDate, projectId) => http.get(`/dashboard/haulingTruck/${startDate}/${endDate}/${projectId}`),

        haulingStackTruckPerMonth: (startDate, endDate, projectId) => http.get(`/dashboard/haulingStackTruckPerMonth/${startDate}/${endDate}/${projectId}`),
        haulingStackTruckPerYear: (year, projectId) => http.get(`/dashboard/haulingStackTruckPerYear/${year}/${projectId}`),

        performanceTruckHaulingMeterMonth: (startDate, endDate, projectId, energyId) => http.get(`/dashboard/performanceTruckHaulingMeterMonth/${startDate}/${endDate}/${projectId}/${energyId}`),
        performanceTruckEnergyPerMonth: (startDate, endDate, projectId) => http.get(`/dashboard/performanceTruckEnergyPerMonth/${startDate}/${endDate}/${projectId}`),
        performanceTruckHaulingVolumeMonth: (startDate, endDate, projectId) => http.get(`/dashboard/performanceTruckHaulingVolumeMonth/${startDate}/${endDate}/${projectId}`),
        performanceTruckHaulingMeterYear: (year, projectId, energyId) => http.get(`/dashboard/performanceTruckHaulingMeterYear/${year}/${projectId}/${energyId}`),
        performanceTruckEnergyPerYear: (year, projectId) => http.get(`/dashboard/performanceTruckEnergyPerYear/${year}/${projectId}`),
        performanceTruckHaulingVolumeYear: (year, projectId) => http.get(`/dashboard/performanceTruckHaulingVolumeYear/${year}/${projectId}`),

        performancePeriodHaulingMeterMonth: (startDate, endDate, projectId, energyId, truckId) => http.get(`/dashboard/performancePeriodHaulingMeterMonth/${startDate}/${endDate}/${projectId}/${energyId}/${truckId}`),
        performancePeriodEnergyPerMonth: (startDate, endDate, projectId, truckId) => http.get(`/dashboard/performancePeriodEnergyPerMonth/${startDate}/${endDate}/${projectId}/${truckId}`),
        performancePeriodHaulingVolumeMonth: (startDate, endDate, projectId, energyId, truckId) => http.get(`/dashboard/performancePeriodHaulingVolumeMonth/${startDate}/${endDate}/${projectId}/${energyId}/${truckId}`),
        performancePeriodHaulingMeterYear: (year, projectId, energyId, truckId) => http.get(`/dashboard/performancePeriodHaulingMeterYear/${year}/${projectId}/${energyId}/${truckId}`),
        performancePeriodEnergyPerYear: (year, projectId, truckId) => http.get(`/dashboard/performancePeriodEnergyPerYear/${year}/${projectId}/${truckId}`),
        performancePeriodHaulingVolumeYear: (year, projectId, energyId, truckId) => http.get(`/dashboard/performancePeriodHaulingVolumeYear/${year}/${projectId}/${energyId}/${truckId}`),

        yearGroup: (projectId) => http.get(`/dashboard/yearGroup/${projectId}`),

        performanceExcavatorLoadingMeterMonth: (startDate, endDate, projectId, energyId) => http.get(`/dashboard/performanceExcavatorLoadingMeterMonth/${startDate}/${endDate}/${projectId}/${energyId}`),
        performanceExcavatorEnergyPerMonth: (startDate, endDate, projectId) => http.get(`/dashboard/performanceExcavatorEnergyPerMonth/${startDate}/${endDate}/${projectId}`),
        performanceExcavatorLoadingVolumeMonth: (startDate, endDate, projectId) => http.get(`/dashboard/performanceExcavatorLoadingVolumeMonth/${startDate}/${endDate}/${projectId}`),
        performanceExcavatorLoadingMeterYear: (year, projectId, energyId) => http.get(`/dashboard/performanceExcavatorLoadingMeterYear/${year}/${projectId}/${energyId}`),
        performanceExcavatorEnergyPerYear: (year, projectId) => http.get(`/dashboard/performanceExcavatorEnergyPerYear/${year}/${projectId}`),
        performanceExcavatorLoadingVolumeYear: (year, projectId) => http.get(`/dashboard/performanceExcavatorLoadingVolumeYear/${year}/${projectId}`),

        performanceExcavatorPeriodLoadingMeterMonth: (startDate, endDate, projectId, energyId, machineId) => http.get(`/dashboard/performanceExcavatorPeriodLoadingMeterMonth/${startDate}/${endDate}/${projectId}/${energyId}/${machineId}`),
        performanceExcavatorPeriodEnergyPerMonth: (startDate, endDate, projectId, machineId) => http.get(`/dashboard/performanceExcavatorPeriodEnergyPerMonth/${startDate}/${endDate}/${projectId}/${machineId}`),
        performanceExcavatorPeriodLoadingVolumeMonth: (startDate, endDate, projectId, machineId) => http.get(`/dashboard/performanceExcavatorPeriodLoadingVolumeMonth/${startDate}/${endDate}/${projectId}/${machineId}`),
        performanceExcavatorPeriodLoadingMeterYear: (year, projectId, energyId, machineId) => http.get(`/dashboard/performanceExcavatorPeriodLoadingMeterYear/${year}/${projectId}/${energyId}/${machineId}`),
        performanceExcavatorPeriodEnergyPerYear: (year, projectId, machineId) => http.get(`/dashboard/performanceExcavatorPeriodEnergyPerYear/${year}/${projectId}/${machineId}`),
        performanceExcavatorPeriodLoadingVolumeYear: (year, projectId, machineId) => http.get(`/dashboard/performanceExcavatorPeriodLoadingVolumeYear/${year}/${projectId}/${machineId}`),

        performanceCrawlerDrill: (startDate, endDate) => http.get(`/dashboard/performanceCrawlerDrill/${startDate}/${endDate}`),

        dieselPerMonth: (startDate, endDate, projectId) => http.get(`/dashboard/dieselPerMonth/${startDate}/${endDate}/${projectId}`),
        dieselPerYear: (year, projectId) => http.get(`/dashboard/dieselPerYear/${year}/${projectId}`),

        energySeviceCarMonth: (startDate, endDate) => http.get(`/dashboard/energySeviceCarMonth/${startDate}/${endDate}`),
        energySeviceCarYear: (year) => http.get(`/dashboard/energySeviceCarYear/${year}`),
        serviceCarPerform: (startDate, endDate, machineTypeId, projectId) => http.get(`/dashboard/serviceCarPerform/${startDate}/${endDate}/${machineTypeId}/${projectId}`),
        energyConsumingMachineGroup: (startDate, endDate, projectId) => http.get(`/dashboard/energyConsumingMachineGroup/${startDate}/${endDate}/${projectId}`),

        loadingSpotZone: (startDate, endDate, projectId) => http.get(`/dashboard/loadingSpotZone/${startDate}/${endDate}/${projectId}`),

        haulingWeight: (startDate, endDate) => http.get(`/dashboard/haulingWeight/${startDate}/${endDate}`),
        haulingEnergy: (startDate, endDate) => http.get(`/dashboard/haulingEnergy/${startDate}/${endDate}`),

        fixedTank: (item) => http.get(`/dashboard/fixedTank?supplyType=${item.supplyType}&workingDate=${item.workingDate}&projectId=${item.projectId}`),
        latestDispenseRecord: (projectId) => http.get(`/dashboard/latestDispenseRecord?projectId=${projectId}`),

        projectEnergy: (startDate, endDate) => http.get(`/dashboard/projectEnergy/${startDate}/${endDate}`),
        dieselRemaining: (workingDate) => http.get(`/dashboard/dieselRemaining?workingDate=${workingDate}`),

        pmActivityPerMonth: (startDate, endDate) => http.get(`/dashboard/listPmActivity/${startDate}/${endDate}`),
        
    },

    bookmark: {
        get: (email) => http.get(`/bookmark/${email}`),
        create: (data) => http.post('/bookmark', data),
        update: (email, data) => http.post(`/bookmark/${email}`, data)
    },

    energySupply: {
        create: (data) => http.post('/energySupply', data),
        update: (id, data) => http.post(`/energySupply/${id}`, data),
        list: () => http.get('/energySupply'),
        destroy: (id) => http.delete(`/energySupply/${id}`),
        mobileByDept: (departmentId) => http.get(`/energySupply/mobileByDept?departmentId=${departmentId}`),
        energySupplyByDept: (departmentId) => http.get(`/energySupply/energySupplyByDept?departmentId=${departmentId}`),

    },

    report: {
        lastMileage: (projectId) => http.get(`/report/lastMileage/${projectId}`),
        lastMeter: (projectId) => http.get(`/report/lastMeter?projectId=${projectId}`),
        lastMajorRow: (projectId) => http.get(`/report/lastMajorRow/${projectId}`),
        listAvgMeter: (backDays) => http.get(`/report/listAvgMeter?backDays=${backDays}`),
        
        listcoalTransportByMachineId: (startDate, projectId) => http.get(`/report/listcoalTransportByMachineId?startDate=${startDate}&projectId=${projectId}`),
        loadingTruckTrip: (startDate, projectId) => http.get(`/report/loadingTruckTrip/${startDate}/${projectId}`),
        loadingExcavatorTrip: (startDate, projectId) => http.get(`/report/loadingExcavatorTrip/${startDate}/${projectId}`),
        loadingsupportTrip: (startDate, projectId) => http.get(`/report/loadingsupportTrip/${startDate}/${projectId}`),
        haulingProductionTrip: (startDate, projectId,timezone) => http.get(`/report/haulingProductionTrip/${startDate}/${projectId}/${timezone}`),
        
        listAvgMeterByMachineIdAndGroup: (startDate, projectId) => http.get(`/report/listAvgMeterByMachineIdAndGroup/${startDate}/${projectId}`),
        listTruckMeterByMachineIdAndGroup: (startDate, projectId) => http.get(`/report/listTruckMeterByMachineIdAndGroup/${startDate}/${projectId}`),
        listSupportMeterByMachineIdAndGroup: (startDate, projectId) => http.get(`/report/listSupportMeterByMachineIdAndGroup/${startDate}/${projectId}`),

        productionReportITDcreate: (data) => http.post('/report/productionReportITDcreate', data),
        findByWorkingDateAndShiftId: (startDate, shiftId) => http.get(`/report/findByWorkingDateAndShiftId/${startDate}/${shiftId}`),

        listAvgMeterByMachineId: (backDays, machineId) => http.get(`/report/listAvgMeterByMachineId?backDays=${backDays}&machineId=${machineId}`),
        haulingProduction: (startDate, endDate, projectId) => http.get(`/report/haulingProduction/${startDate}/${endDate}/${projectId}`),
        loadingProduction: (startDate, endDate, projectId) => http.get(`/report/loadingProduction/${startDate}/${endDate}/${projectId}`),
        haulingProductionTimes: (startDate, endDate, projectId) => http.get(`/report/haulingProductionTimes/${startDate}/${endDate}/${projectId}`),
        loadingProductionTimes: (startDate, endDate, projectId) => http.get(`/report/loadingProductionTimes/${startDate}/${endDate}/${projectId}`),
        supportMachineMajor: (startDate, endDate, projectId) => http.get(`/report/supportMachineMajor/${startDate}/${endDate}/${projectId}`),
        supportMachineActivity: (startDate, endDate, projectId) => http.get(`/report/supportMachineActivity/${startDate}/${endDate}/${projectId}`),
        dailyTruckTrip: (startDate, endDate, projectId) => http.get(`/report/dailyTruckTrip/${startDate}/${endDate}/${projectId}`),
        energyUsageProject: (startDate, endDate, projectId) => http.get(`/report/energyUsageProject/${startDate}/${endDate}/${projectId}`),
        energyUsageHistory: (startDate, endDate, machineId) => http.get(`/report/energyUsageHistory/${startDate}/${endDate}/${machineId}`),
        listActiveAdjustedStockReport: (timeStr,timeEnd) => http.get(`/report/listActiveAdjustedStockReport/${timeStr}/${timeEnd}`),
        listMaterialRequestReport: () => http.get(`/report/listMaterialRequestReport`),
   
    },

    truckRFID: {
        create: (data) => http.post('/truckRFID', data),
        update: (id, data) => http.post(`/truckRFID/${id}`, data),
        findByProjectId: (projectId) => http.get(`truckRFID/findByProjectId/${projectId}`)
    },

    truckXPPL: {
        create: (data) => http.post('/truckXPPL', data),
        update: (id, data) => http.post(`/truckXPPL/${id}`, data),
        findByProjectId: (projectId) => http.get(`truckXPPL/findByProjectId/${projectId}`)
    },

    workGroup: {
        create: (data) => http.post('/workGroup', data),
        update: (id, data) => http.post(`/workGroup/${id}`, data),
        list: () => http.get('/workGroup'),
        destroy: (id) => http.delete(`/workGroup/${id}`)
    },

    machineWorkGroup: {
        create: (data) => http.post('/machineWorkGroup', data),
        update: (data) => http.put('/machineWorkGroup', data),
        destroy: (machineId) => http.delete(`/machineWorkGroup/${machineId}`),
        findByMachineId: (machineId) => http.get(`/machineWorkGroup/${machineId}`),
        list: () => http.get('/machineWorkGroup')
    },

    energyUsageFile: {
        create: (data) => http.post('/energyUsageFile', data),
        update: (id, data) => http.put(`/energyUsageFile/${id}`, data),
        listBetweenDate: (startDate, endDate, projectId) => http.get(`/energyUsageFile/listBetweenDate/${startDate}/${endDate}/${projectId}`),
        listByEnergySupply: (workingDate, energySupply) => http.get(`/energyUsageFile/listByEnergySupply?workingDate=${workingDate}&energySupply=${energySupply}`)
    },

    permit: {
        create: (data) => http.post('/permit', data),
        update: (id, data) => http.post(`/permit/${id}`, data),
        findByPk: (id) => http.get(`/permit/findByPk/${id}`),
        destroy: (id) => http.delete(`/permit/${id}`)
    },

    energyApproval: {
        create: (data) => http.post(`/energyApproval`, data),
        update: (id, data) => http.put(`/energyApproval/${id}`, data),
        listBetweenDate: (startDate, endDate, projectId) => http.get(`/energyApproval/listBetweenDate/${startDate}/${endDate}/${projectId}`),
    },

    workItemType: {
        create: (data) => http.post('/workItemType', data),
        update: (id, data) => http.post(`/workItemType/${id}`, data),
        list: () => http.get('/workItemType'),
        destroy: (id) => http.delete(`/workItemType/${id}`)
    },

    processCustom: {
        create: (data) => http.post('/processCustom', data),
        update: (id, data) => http.put(`/processCustom/${id}`, data),
        list: () => http.get('/processCustom'),
        destroy: (id) => http.delete(`/processCustom/${id}`)
    },

    trcloud: {
        searchInventory: (item) => http.get(`/trcloud/searchInventory?item=${item}`),
        retrieveInventory: (item) => http.get(`/trcloud/retrieveInventory?item=${item}`),
        createMR: (data) => http.post(`/trcloud/createMR`, data),
        searchMR: (item) => http.get(`/trcloud/searchMR?item=${item}`),
    },

    energyReceive: {
        create: (data) => http.post('/energyReceive', data),
        update: (id, data) => http.put(`/energyReceive/${id}`, data),
        list: () => http.get('/energyReceive'),
        destroy: (id) => http.delete(`/energyReceive/${id}`),
        listBetweenDate: (startDate, endDate, projectId) => http.get(`/energyReceive/listBetweenDate/${startDate}/${endDate}/${projectId}`),
        getLastId: (energySupplyId) => http.get(`/energyReceive/getLastId?energySupplyId=${energySupplyId}`),
        getBackDate: (days, energySupplyId) => http.get(`/energyReceive/getBackDate?days=${days}&energySupplyId=${energySupplyId}`),
        latestFuelReception: (supplyId, receiveDate) => http.get(`/energyReceive/latestFuelReception?supplyId=${supplyId}&receiveDate=${receiveDate}`),
        latestDateRemaining: (energySupplyId) => http.get(`/energyReceive/latestDateRemaining?energySupplyId=${energySupplyId}`),
    },

    fileServer: {
        upload: (data) => httpUpload.post('/fileServer/upload', data),
        download: (data) => httpUpload.get(`/fileServer/download?item=${data}`, { responseType: 'blob' }),
    },

    documentType: {
        create: (data) => http.post('/documentType', data),
        update: (id, data) => http.put(`/documentType/${id}`, data),
        list: () => http.get('/documentType'),
        destroy: (id) => http.delete(`/documentType/${id}`),
    },

    machineDocument: {
        create: (data) => http.post('/machineDocument', data),
        update: (id, data) => http.put(`/machineDocument/${id}`, data),
        findByMachineId: (machineId) => http.get(`/machineDocument/findByMachineId?machineId=${machineId}`),
        destroy: (id) => http.delete(`/machineDocument/${id}`),
    },

    warehouse: {
        create: (data) => http.post('/warehouse', data),
        update: (id, data) => http.put(`/warehouse/${id}`, data),
        list: () => http.get('/warehouse'),
        destroy: (id) => http.delete(`/warehouse/${id}`),
    },

    projectTrcloud: {
        create: (data) => http.post('/projectTrcloud', data),
        update: (id, data) => http.put(`/projectTrcloud/${id}`, data),
        list: () => http.get('/projectTrcloud'),
        destroy: (id) => http.delete(`/projectTrcloud/${id}`),
    },

    pmTable: {
        create: (data) => http.post('/pmTable', data),
        update: (id, data) => http.put(`/pmTable/${id}`, data),
        list: () => http.get('/pmTable'),
        destroy: (id) => http.delete(`/pmTable/${id}`),

    },

    pmTableItem: {
        create: (data) => http.post('/pmTableItem', data),
        update: (id, data) => http.put(`/pmTableItem/${id}`, data),
        destroy: (id) => http.delete(`/pmTableItem/${id}`),
        listByTableId: (id) => http.get(`/pmTableItem/listByTableId?id=${id}`),
        listGroupPartId: () => http.get(`/pmTableItem/listGroupPartId`),
    },

    pmActivity: {
        create: (data) => http.post('/pmActivity', data),
        update: (id, data) => http.put(`/pmActivity/${id}`, data),
        list: () => http.get('/pmActivity'),
        destroy: (id) => http.delete(`/pmActivity/${id}`),
        search: (data) => http.get(`/pmActivity/search?name=${data}`)
    },

    pmRound: {
        create: (data) => http.post('/pmRound', data),
        update: (id, data) => http.put(`/pmRound/${id}`, data),
        list: () => http.get('/pmRound'),
        destroy: (id) => http.delete(`/pmRound/${id}`),
        search: (data) => http.get(`/pmRound/search?pmTableId=${data}`),
        listByTableId: (id) => http.get(`/pmRound/listByTableId?id=${id}`),
        CreateCopy: (data) => http.post(`/pmRound/CreateCopy`,data),
    },

    pmCategory: {
        create: (data) => http.post('/pmCategory', data),
        update: (id, data) => http.put(`/pmCategory/${id}`, data),
        list: () => http.get('/pmCategory'),
        destroy: (id) => http.delete(`/pmCategory/${id}`),
    },


    pmUnit: {
        create: (data) => http.post('/pmUnit', data),
        update: (id, data) => http.put(`/pmUnit/${id}`, data),
        list: () => http.get('/pmUnit'),
        destroy: (id) => http.delete(`/pmUnit/${id}`),
    },

    pmPart: {
        create: (data) => http.post('/pmPart', data),
        findSecondPart: (parts) => http.get(`/pmPart/findSecondPart/${parts}`),
        update: (id, data) => http.put(`/pmPart/${id}`, data),
        bulkUpdate: (data) => http.post('/pmPart/bulkUpdate', data),
        list: () => http.get(`/pmPart`),
        searchPart: (name) => http.get(`/pmPart/searchPart/${name}`),
        destroy: (id) => http.delete(`/pmPart/${id}`),
    },

    pmTableMachine: {
        create: (data) => http.post('/pmTableMachine', data),
        update: (id, data) => http.put(`/pmTableMachine/${id}`, data),
        destroy: (id) => http.delete(`/pmTableMachine/${id}`),
        list: () => http.get(`/pmTableMachine`),
        listMaxId: () => http.get(`/pmTableMachine/listMaxId`),
        countTableUsage: () => http.get(`/pmTableMachine/countTableUsage`)
    },

    pmActualRound: {
        create: (data) => http.post('/pmActualRound', data),
        update: (id, data) => http.put(`/pmActualRound/${id}`, data),
        destroy: (id,roundNumber) => http.delete(`/pmActualRound/${id}/${roundNumber}`),
        destroyId: (id) => http.delete(`/pmActualRound/actualId/del/${id}`),
        listMaxId: () => http.get(`pmActualRound/listMaxId`),
        listMaxIdNotClosingWork: () => http.get(`pmActualRound/listMaxIdNotClosingWork`),
        list: () => http.get(`/pmPart`),
        checkPlanIsNotFound: () => http.get(`pmActualRound/checkPlanIsNotFound`),
        historyRounds: (id) => http.get(`pmActualRound/historyRounds/${id}`),
        historyRoundsIsNull: () => http.get(`pmActualRound/historyRoundsIsNull`)

    },
    pmAppointmentAction: {
        create: (data) => http.post('/pmAppointmentAction', data),
        update: (data) => http.put(`/pmAppointmentAction`, data),
        destroy: (id) => http.delete(`/pmAppointmentAction/${id}`),
        searchID: (id, round,pmActualRoundId) => http.get(`/pmAppointmentAction/searchTableID/${id}/${round}/${pmActualRoundId}`),
        listID: (id) => http.get(`/pmAppointmentAction/${id}`),

    },

    pmFramework: {
        create: (data) => http.post('/pmFramework', data),
        update: (id, data) => http.put(`/pmFramework/${id}`, data),
        list: () => http.get('/pmFramework'),
        destroy: (id) => http.delete(`/pmFramework/${id}`),
    },

    pmAppointment: {
        create: (data) => http.post(`/pmAppointment`, data),
        update: (id, data) => http.put(`/pmAppointment/${id}`, data),
        listActive: () => http.get('/pmAppointment/listActive'),
        destroy: (id) => http.delete(`/pmAppointment/${id}`),
        listActiveNotApproved: () => http.get('/pmAppointment/listActiveNotApproved'),
        listApproved: () => http.get('/pmAppointment/listApproved')
    },

    pmMrApproval: {
        create: (data) => http.post('/pmMrApproval', data),
        bulkUpdate: (data) => http.post('/pmMrApproval/bulkUpdate', data),
        listActiveApproved: () => http.get('/pmMrApproval/listActiveApproved'),
        listActiveAdjustedStock: (timed) => http.get(`/pmMrApproval/listActiveAdjustedStock/${timed}`),
        destroy: (pmAppointmentId) => http.delete(`/pmMrApproval/${pmAppointmentId}`),
        findIdMrApproved: (id,data) => http.post(`/pmMrApproval/findIdMrApproved/${id}`,data),
    },

    pmDocFile: {
        create: (data) => http.post('/pmDocFile', data),
        bulkUpdate: (data) => http.post('/pmDocFile/bulkUpdate', data),
        listID: (id) => http.get(`/pmDocFile/listID/${id}`),
        destroy: (name) => http.delete(`/pmDocFile/${name}`),
        destroyId: (id) => http.delete(`/pmDocFile/daleteId/${id}`),

    },

    pmPartFile: {
        create: (data) => http.post('/pmPartFile', data),
        bulkUpdate: (data) => http.post('/pmPartFile/bulkUpdate', data),
        listID: (id) => http.get(`/pmPartFile/listID/${id}`),
        destroy: (name) => http.delete(`/pmPartFile/${name}`),
        destroyId: (id) => http.delete(`/pmPartFile/daleteId/${id}`),

    },

    pmMr: {
        create: (data) => http.post('/pmMr', data),
        getMaxMrno: (startDate, endDate) => http.get(`/pmMr/getMaxMrno/${startDate}/${endDate}`),
        update: (data) => http.put(`/pmMr`, data),
        getMrnoCutting: () => http.get(`/pmMr/getMrnoCutting/list`),
        destroy: (id) => http.delete(`/pmMr/${id}`),
    },

    machineBlockMr: {
        create: (data) => http.post('/machineBlockMr', data),
        update: (id, data) => http.put(`/machineBlockMr/${id}`, data),
        list: () => http.get('/machineBlockMr'),
        destroy: (id) => http.delete(`/machineBlockMr/${id}`),
    },
    machineGroup: {
        create: (data) => http.post('/machineGroup', data),
        update: (data) => http.put(`/machineGroup`, data),
        destroy: (id) => http.delete(`/machineGroup/${id}`),
        list: (id) => http.get(`/machineGroup/${id}`),
    },
    machineGroupItem: {
        create: (data) => http.post('/machineGroupItem', data),
        update: (data) => http.put(`/machineGroupItem`, data),
        list: () => http.get('/machineGroupItem/list'),
        destroy: (id) => http.delete(`/machineGroupItem/${id}`),
    },
    distanceMatrix: {
        create: (data) => http.post('/distanceMatrix', data),
        update: (id, data) => http.put(`/distanceMatrix/${id}`, data),
        list: (projectId) => http.get(`/distanceMatrix?projectId=${projectId}`),
        destroy: (id) => http.delete(`/distanceMatrix/${id}`),
    },

    energyConsumptionGroup: {
        create: (data) => http.post('/energyConsumptionGroupRoute', data),
        update: (id, data) => http.post(`/energyConsumptionGroupRoute/${id}`, data),
        list: () => http.get('/energyConsumptionGroupRoute'),
        destroy: (id) => http.delete(`/energyConsumptionGroupRoute/${id}`)
    },

    logLogin: {
        create: (data) => http.post('/logLogin', data),
        update: (id, data) => http.post(`/logLogin/${id}`, data),
        getLastLoginToday: () => http.get('/logLogin/getLastLoginToday'),
        listBetweenDate: (startDate,endDate) => http.get(`/logLogin/listBetweenDate/${startDate}/${endDate}`),
        getIp: () => axios.get('https://api.ipify.org?format=json')
    },
    cmRepairRequests:{
        create: (data) => http.post('/cmRepairRequests', data),
        listMaxTicket: () => http.get('/cmRepairRequests/maxticket'),
        list: () => http.get(`/cmRepairRequests/list`),
        destroy: (id) => http.delete(`/cmRepairRequests/${id}`),
        update: (id, data) => http.patch(`/cmRepairRequests/${id}`, data),
        findWorkDateBytechnician: (data) => http.post(`/cmRepairRequests/findWorkDateBytechnician`, data),

    },
    cmTicket:{
        create: (data) => http.post('/cmTicket', data),
        update: (id,data) => http.patch(`/cmTicket/${id}`, data),
        findBymachineId: (machineId) => http.get(`/cmTicket/findBymachineId/${machineId}`),
        list: () => http.get(`/cmTicket/list`),
        findById: (id)=> http.get(`/cmTicket/${id}`),
    },
    cmFile:{
        create: (data) => http.post('/cmFile', data),
        list: () => http.get(`/cmFile`),
        update: (id, data) => http.post(`/cmFile/${id}`, data),
        destroy: (id) => http.delete(`/cmFile/${id}`),
  
    },
    cmMeter:{
        create: (data) => http.post('/cmMeter', data),
        list: () => http.get(`/cmMeter`),
        update: (id, data) => http.post(`/cmMeter/${id}`, data),
        destroy: (id) => http.delete(`/cmMeter/${id}`),

    },
    cmFailure:{
        create: (data) => http.post('/cmFailure', data),
        list: () => http.get(`/cmFailure`),
        findById: (id)=> http.get(`/cmFailure/${id}`),
        update: (id, data) => http.post(`/cmFailure/${id}`, data),
        destroy: (id) => http.delete(`/cmFailure/${id}`),

    },
    cmLocationMachine:{
        create: (data) => http.post('/cmLocationMachine', data),
        list: () => http.get(`/cmLocationMachine`),
        findById: (id)=> http.get(`/cmLocationMachine/${id}`),
        update: (id, data) => http.post(`/cmLocationMachine/${id}`, data),
        destroy: (id) => http.delete(`/cmLocationMachine/${id}`),

    },
    cmEvaluations:{
        create: (data) => http.post('/cmEvaluations', data),
        list: () => http.get(`/cmEvaluations`),
        findById: (id)=> http.get(`/cmEvaluations/${id}`),
        update: (id, data) => http.patch(`/cmEvaluations/${id}`, data),
        destroy: (id) => http.delete(`/cmEvaluations/${id}`),
    },
    cmRatingCriteria:{
        create: (data) => http.post('/cmRatingCriteria', data),
        list: () => http.get(`/cmRatingCriteria`),
        update: (id, data) => http.post(`/cmRatingCriteria/${id}`, data),
        destroy: (id) => http.delete(`/cmRatingCriteria/${id}`),
    },
    cmRepairRatingDetail:{
        create: (data) => http.post('/cmRepairRatingDetail', data),
        list: () => http.get(`/cmRepairRatingDetail`),
        update: (id, data) => http.post(`/cmRepairRatingDetail/${id}`, data),
        destroy: (id) => http.delete(`/cmRepairRatingDetail/${id}`),
    },
    cmRepairRating: {
        create: (data) => http.post('/cmRepairRating', data),
        list: () => http.get(`/cmRepairRating`),
        update: (id, data) => http.post(`/cmRepairRating/${id}`, data),
        destroy: (id) => http.delete(`/cmRepairRating/${id}`),
    },
    cmLog: {
        create: (data) => http.post('/cmLog', data),
    },
    cmOperation: {
        create: (data) => http.post('/cmOperation', data),
        list: () => http.get(`/cmOperation`),
        update: (id, data) => http.post(`/cmOperation/${id}`, data),
        destroy: (id) => http.delete(`/cmOperation/${id}`),
    },
    cmOperationDetail: {
        create: (data) => http.post('/cmOperationDetail', data),
        list: () => http.get(`/cmOperationDetail`),
        update: (id, data) => http.post(`/cmOperationDetail/${id}`, data),
        destroy: (id) => http.delete(`/cmOperationDetail/${id}`),
    },
    cmRepair: {
        create: (data) => http.post('/cmRepair', data),
        list: () => http.get(`/cmRepair`),
        update: (id, data) => http.post(`/cmRepair/${id}`, data),
        destroy: (id) => http.delete(`/cmRepair/${id}`),
    }
    

}

export { mesDataService }