
const mutations = {
    CREATE_ITEM(state, item) {
        // Add a new item to the state
        state.data.push(item);
    },

    READ_ITEM(state, id) {
        // Return the item with the given ID
        return state.data.find(obj => obj.id === id) || null;
    },

    UPDATE_ITEM(state, updatedItem) {
        state.data = state.data.map(item =>
            item.id === updatedItem.id ? { ...item, ...updatedItem } : item
        );
    },

    DELETE_ITEM(state, id) {
        // Remove the item with the given ID
        state.data = state.data.filter(obj => obj.id !== id);
    },

    BULK_ITEMS(state, items) {
        // Create a map of items by their ID for quick lookup
        const itemMap = new Map(items.map(item => [item.id, item]));

        // Update existing items or add new items from the incoming `items` array
        state.data = state.data.map(existingItem => {
            if (itemMap.has(existingItem.id)) {
                // Merge and update the existing item if a matching item is found
                return { ...existingItem, ...itemMap.get(existingItem.id) };
            }
            return existingItem;
        });

        // Add new items that don't exist in the state data
        const newItems = items.filter(item => !state.data.some(existingItem => existingItem.id === item.id));
        state.data = [...state.data, ...newItems];

        // Remove items that are not present in the new batch
        const itemIds = new Set(items.map(item => item.id));
        state.data = state.data.filter(obj => itemIds.has(obj.id));
    }
}


const actions = {
    
    createItem({ commit }, item) {
        commit('CREATE_ITEM', item);
    },

    readItem({ commit }, id) {
        return commit('READ_ITEM', id);
    },

    updateItem({ commit }, updatedItem) {
        commit('UPDATE_ITEM', updatedItem);
    },

    deleteItem({ commit }, id) {
        commit('DELETE_ITEM', id);
    },

    bulkItems({ commit }, items) {
        commit('BULK_ITEMS', items);
    },
}



export { mutations, actions };


