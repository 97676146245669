<template>
  <div>
    <v-app>
      <div class="header">
        <div class="navbar">
          <div class="logo">
            <router-link to="/"><img src="@/assets/logo.png" style="    height: 42px;   width: auto;"></router-link>
          </div>
          <nav>
            <ul :style="{ maxHeight: menuHeight }">
              <li v-for="(item, itemIndex) in items" :key="itemIndex" :class="{ actionTab: sysbar.title === item.title }">

                <v-menu v-if="item.title === 'Maintenance'" offset-y transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" >
                      <v-icon>{{ item.icon }}</v-icon>
                      {{ item.title }}
                    </div>
                  </template>
                  <v-list>
                    <v-list-item link v-for="(item, index) in itemsMaintenance" :key="index">
                      <v-list-item-title v-if="item.link === '/maintenance'" @click="linkFn(item)"> <v-icon>{{ item.icon
                          }}</v-icon> {{
                        item.title }}</v-list-item-title>
                      <v-list-item-title v-else style="opacity: 0.3;" @click.shift="linkFn(item)"> <v-icon>{{ item.icon
                          }}</v-icon> {{
                        item.title }}</v-list-item-title>

                    </v-list-item>
                  </v-list>
                </v-menu>

                <div v-else :class="{ actionTab: sysbar.title === item.title }" @click="linkFn(item)">
                  <v-icon>{{ item.icon }}</v-icon> {{ item.title }}
                </div>
              </li>


              <li v-show="!user">
                <router-link to="/register">
                  <v-icon>mdi-registered-trademark</v-icon>Register
                </router-link>
              </li>

              <li v-if="this.$store.state.authen.user" @click="signOutFn()">
                <v-icon>mdi-arrow-right-circle</v-icon>Signout
              </li>
            </ul>
          </nav>

          <v-app-bar-nav-icon class="menu-icon" @click.prevent="menuToggle()"></v-app-bar-nav-icon>
        </div>

      </div>
      <v-container fluid>
        <div>
          <v-system-bar style="min-height:32px;height: auto;" window color="grey lighten-4">

            <v-icon>{{ sysbar.icon }}</v-icon>

            <span class="mr-2" v-if="user">{{ sysbar.title }}
              <span v-show="!isMaintenanceSelected" class="title font-weight-black"
                v-if="bookmarkObj && bookmarkObj.projectId">
                {{ bookmarkObj.project.name }} - {{ bookmarkObj.project.nameEn }}
              </span>
            </span>
            <!-- Maintenace project selected -->
            <span class="d-flex flex-wrap"
              v-if="user && mainMenuSelected && (mainMenuSelected.link === '/maintenance' || mainMenuSelected.link === '/maintenance/correctiveMaintenance')">
              <v-chip-group v-model="mtProjectSelected" active-class="primary--text" multiple column>
                <v-chip filter outlined small v-for="item in systemBarData" :key="item.id" :value="item.id"><span
                    class="font-sarabun">{{ item.name
                    }}</span></v-chip>

                <span style="border-left: 1px solid #a0a0a0;padding-right: 4px;"></span> <v-btn class="mt-1" outlined
                  rounded="" small link color="#ba0001" @click="mtProjectSelected = []"><span class="font-sarabun">
                    <v-icon class="ml-2 mt-1 mb-1" color="#ba0001"
                      style="cursor: pointer;">mdi-checkbox-marked-circle-minus-outline</v-icon>
                    Uncheck All</span></v-btn>
              </v-chip-group>


            </span>






            <v-spacer></v-spacer>

            <!-- Production project selected -->
            <div class="d-flex justify-end mt-3" v-if="user && !isMaintenanceSelected">
              <v-btn x-small @click="drawer = true" dark><v-icon color="white">mdi-label</v-icon>โครงการ</v-btn>
              <v-navigation-drawer dark v-model="drawer" absolute right temporary>
                <v-radio-group class="ml-2" dense v-model="projectSelected">
                  <v-radio color="white" class="mr-2" v-for="item in projectRoleData" :key="item.projectId"
                    :label="item.project.name" :value="item.projectId">
                  </v-radio>
                </v-radio-group>
              </v-navigation-drawer>
            </div>
          </v-system-bar>
        </div>


        <v-main class="pa-2">
          <alert-component></alert-component>
          <loading-component v-show="loading" />
          <router-view></router-view>
        </v-main>

      </v-container>
    </v-app>

    <!-- -----------------------footer-------------------------- -->
    <div class="footer">
      <v-container fluid>
        <hr>
        <p class="copyright">Copyright © 2023 Thaidrill Co.,Ltd. All rights reserved.</p>
      </v-container>
    </div>

    <v-scale-transition>
      <v-btn fab v-show="fab" v-scroll="onScroll" dark fixed bottom right color="secondary" @click="toTop">
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>

  </div>
</template>

<script>
import AlertComponent from '@/components/system/AlertComponent.vue'
import { mapActions, mapState } from 'vuex';
import LoadingComponent from '@/components/system/LoadingComponent.vue';
import { utils } from '@/libs/utils';

export default {
  components: {
    AlertComponent,
    LoadingComponent
  },
  data: () => ({
    mtProjectSelectedTimeout: null,
    accessedProjectSelected: false,
    systemBarSelectedData: [],
    fab: null,
    color: "",
    flat: null,
    padless: false,
    menuHeight: '0px',
    drawer: false,
    projectSelected: null,
    mainMenuSelected: null,
    isMaintenanceSelected: false,
    projectRoleData: [],
    maintenanceProjects: [],
    maintenanceProjectSelected: [],
    items: [
      {
        title: "DASHBOARD",
        icon: "mdi-chart-pie",
        link: "/"
      },
      {
        title: "Production",
        icon: "mdi-calendar-month",
        link: "/production"
      },
      {
        title: "Maintenance",
        icon: "mdi-car-wrench",
        link: "/maintenance",
      },

      {
        title: "Setting",
        icon: "mdi-cog",
        link: "/setting"
      },
      {
        title: "Report",
        icon: "mdi-chart-box-outline",
        link: "/report"
      },

    ],
    itemsMaintenance: [
      {
        title: "PM [Preventive Maintenance]",
        icon: "mdi-car-wrench",
        link: "/maintenance",
      },
      {
        title: "CM [Corrective Maintenance]",
        icon: "mdi-chart-box-outline",
        link: "/maintenance/correctiveMaintenance"
      },
    ],
    sysbar: [
      { icon: "" },
      { title: "" }
    ],



  }),


  async mounted() {
   
    this.checkTab()
    await this.fetchUser();
  },

  


  methods: {
    ...mapActions('authen', ['fetchUser']),
    ...mapActions('project', { projectList: 'list' }),
    ...mapActions('projectRoles', { findProjectRoles: 'findByMemberId' }),
    ...mapActions('bookmark', { createBookmark: 'create', updateBookmark: 'update', getBookmark: 'get' }),
    ...mapActions('logLogin', { getLastLoginToday: 'getLastLoginToday', logLoginUpdate: 'update' }),


    menuToggle() {
      (this.menuHeight == '0px') ? this.menuHeight = '200px' : this.menuHeight = '0px'

    },

    linkFn(item) {

      this.mainMenuSelected = item
      if (item.link.split('/')[1] === 'maintenance') {
        this.sysbar.icon = item.icon
        this.sysbar.title = 'Maintenance'
      } else {
        this.sysbar.icon = item.icon
        this.sysbar.title = item.title
      }

      if (item.link === "/report") {
        let route = this.$router.resolve({ path: "/report" });
        window.open(route.href, '_blank');
      } else {
        this.$router.push({ path: item.link }).catch(() => { })
      }
    },
   

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    startTimeout() {
      if (this.mtProjectSelectedTimeout) {
        clearTimeout(this.mtProjectSelectedTimeout); // Clear any existing timeout
      }
      this.mtProjectSelectedTimeout = setTimeout(() => {
        this.$store.commit('utils/SET_SYSTEM_BAR_SELECTED', this.systemBarSelectedData); // Commit the value after 3 seconds
      }, 1100);
    },
    async signOutFn() {
      this.sysbar.icon = this.items[0].icon;
      this.sysbar.title = this.items[0].title;

      const lastLoginObj = await this.getLastLoginToday();

      if (lastLoginObj) {
        const logUpdateObj = {
          id: lastLoginObj.id,
          logoutTimestamp: Date.now(),
        }
        await this.logLoginUpdate(logUpdateObj)
      }

      await this.$store.dispatch('authen/signout')
    },


    async bookmarkFn(projectId) {

      try {
        const payload = {
          projectId: projectId,
          lastLogin: new Date()
        }


        if (this.bookmarkObj) {
          await this.updateBookmark(payload)
        } else {
          await this.createBookmark(payload)
        }

        await this.getBookmark(this.user.email)



      } catch (e) {
        console.log(e)
      }


    },

    async initialFn(userObj) {
      this.projectList();

      let roleData = await this.findProjectRoles(userObj.id)
      roleData = roleData.map(role => {

        return {
          ...role,
          projectName: role.project.name
        }
      })

      this.projectRoleData = utils.sortThaiDictionary(roleData, 'projectName');

      await this.getBookmark(userObj.email)

      this.projectSelected = this.bookmarkObj?.projectId ?? null;

      // create default bookmark
      if (this.projectRoleData.length === 1) {
        this.projectSelected = this.projectRoleData[0].projectId
      }
    },
    checkTab() {
      const currentLinkTrue =  window.location.hash.replace('#', '');
     
      const currentLinkMenu =  window.location.hash.replace('#', '').split('/')[1];
      const currentItem = this.items.find(item => item.link === '/' + currentLinkMenu); //หลอก

      if (currentItem) {
        if (currentLinkMenu === 'maintenance') {
          const currentItemTrue = this.itemsMaintenance.find(item => item.link === currentLinkTrue); //จริง
          this.mainMenuSelected = currentItemTrue
        }
        this.sysbar.icon = currentItem.icon;
        this.sysbar.title = currentItem.title;
      } else {
        this.sysbar.icon = this.items[0].icon;
        this.sysbar.title = this.items[0].title;
      }


    }

  },

  computed: {
    ...mapState('utils', ['loading', 'systemBarData', 'systemBarSelected']),
    ...mapState('project', { projectData: 'data' }),
    ...mapState('authen', ['user']),
    ...mapState('bookmark', { bookmarkObj: 'data' }),

    mtProjectSelected: {
      get() {
        return this.systemBarSelected
      },
      set(val) {
        this.systemBarSelectedData = val; // Update the local value
        this.startTimeout();
      }
    },
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },

    user(obj) {

      if (obj) {
        this.initialFn(obj)
      }

    },

    projectSelected(id) {
      if (this.user) {
        this.bookmarkFn(id)
      }

    },

    mainMenuSelected(val) {
      this.isMaintenanceSelected = val?.link.split('/')[1] === 'maintenance';

    },









  }

}
</script>

<style>
.font-sarabun {
  font-family: "Sarabun", sans-serif !important;
}

html, body {
    overflow-x: hidden;
}

.container {
  padding: 0;
}

.navbar {
  display: flex;
  align-items: flex-start;
  padding: 5px;
}

nav {
  flex: 1;
  text-align: right;
}

nav ul {
  list-style-type: none;
}

nav ul li {
  display: inline-block;
  /* margin-right: 20px; */
  padding: 2px 14px;
  background: #ffffff;
  border-left: 1px solid #ffc8c8;

}

nav ul li:hover {
  cursor: pointer;

}

nav ul li::after {
  content: '';
  width: 0%;
  height: 2px;
  background: #f44336;
  display: block;
  margin: auto;
  transition: 0.5s;
}

nav ul li:hover::after {
  width: 100%;
}

a {
  text-decoration: none;
  color: #555 !important;
}

p {
  color: #555;
}

.menu-icon {

  display: none !important;
}

.logo {
  margin: -9px 0;
  /* margin-top: -10px; */
  cursor: pointer;
}


/* -------------------media query for menu ------------------ */
@media only screen and (max-width: 800px) {
  nav ul {
    z-index: 999;
    position: absolute;
    top: 60px;
    left: 0;
    background: #333;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.5s;
  }

  nav ul li {
    display: block;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  nav ul li,
  nav ul li i {
    color: #fff !important;
  }

  .menu-icon {
    margin-top: -17px;
    display: block !important;
    cursor: pointer;
  }


  .header {
    height: 30px;
  }

  .logo img {
    margin-top: -6px;
    width: 50px;
  }
}



/* -------------footer ------------------ */
.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background: #464343;
  color: #8a8a8a;
  font-size: 14px;
  padding: 0 0 20px;
}

.footer p {
  color: #8a8a8a;
}

.footer hr {
  border: none;
  background: #b5b5b5;
  height: 1px;
  margin: 20px 0;
}

.copyright {
  text-align: center;
}

.actionTab {
  background: #b61c1c;
  color: #fff;
}

.actionTab>i {
  color: #fff !important;
}

.readonly-background {
  background-color: #f5f5f5; 
}
</style>