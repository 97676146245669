const utils = {

  checkObjectIsEmpty: (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },

  convertToshortDateTime(dateStr, timeStr) {

    let date = dateStr.split("/");
    let time = timeStr.split(":");
    let finalDate = new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2])

    return finalDate
  },


  groupByName: (arrayData, name) => {
    return new Promise((resolve) => {
      const groupedData = {};
      arrayData.forEach(item => {
        const itemName = item[`${name}`]
        if (!groupedData[itemName]) {
          groupedData[itemName] = [];
        }

        groupedData[itemName].push(item);
      });

      resolve(groupedData) // return object
    })

  },


  sumField(data, key) {

    return data.reduce((a, b) => Number(a) + Number((b[key] || 0)), 0)
  },

  deepCopy: (data) => {
    return JSON.parse(JSON.stringify(data));
  },

  countDaysInCurrentMonth() {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;

    let getDaysInMonth = (year, month) => {
      return new Date(year, month, 0).getDate();
    }

    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

    return daysInCurrentMonth
  },

  countDaysInMonth(myDate) {
    const year = myDate.getFullYear();
    const month = myDate.getMonth() + 1;

    let getDaysInMonth = (year, month) => {
      return new Date(year, month, 0).getDate();
    }

    const daysInMonth = getDaysInMonth(year, month);

    return daysInMonth
  },

  getDifferenceInDays(date1, date2) {

    const diffInMs = Math.abs(date2 - date1);

    return diffInMs / (1000 * 60 * 60 * 24);
  },

  setTimeToDate(date, time) {
    const times = time.split(':')

    date.setHours(times[0]);
    date.setMinutes(times[1]);
    date.setSeconds(times[2]);

    return date
  },

  todayDateString() {
    const date = new Date()
    const dateString = date.toLocaleDateString('en-CA');

    return dateString
  },

  dateString(date) {
    const d = new Date(date)
    const dateString = d.toLocaleDateString('en-CA');

    return dateString
  },

  time24Format(dateStr) {
    const options = { hour12: false };
    const time = new Date(dateStr).toLocaleTimeString('en-US', options)

    return time
  },


  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  },


  differenceTime(startDateTime, endDateTime) {

    const start = new Date(startDateTime).getTime();
    const end = new Date(endDateTime).getTime();

    const differenceInSeconds = Math.abs((end - start) / 1000);
    const hours = Math.floor(differenceInSeconds / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    // const seconds = Math.floor((differenceInSeconds % 3600) % 60);
    return `${hours}:${minutes}`;
  },

  compareTime(startDateTime, endDateTime) {
    const start = new Date(startDateTime).getTime();
    const end = new Date(endDateTime).getTime();

    const diffTime = start - end

    return diffTime
  },

  timeStringToFloat(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

    return hours + minutes / 60;
  },


  shiftHours(startTime, endTime) {
    let today = new Date()
    let nextDay = new Date()
    const todayString = today.toLocaleDateString('en-CA')
    let nextDayString = nextDay.toLocaleDateString('en-CA')
    let startShift = todayString + " " + startTime
    let endShift = nextDayString + " " + endTime
    const start = new Date(startShift).getTime();
    const end = new Date(endShift).getTime();

    if (end < start) {
      nextDay.setDate(today.getDate() + 1)
      nextDayString = nextDay.toLocaleDateString('en-CA')
      startShift = todayString + " " + startTime
      endShift = nextDayString + " " + endTime

    }


    const hhmm = this.differenceTime(startShift, endShift)

    return this.timeStringToFloat(hhmm)

  },

  createArrayNumber(maxNumber) {

    return Array.from({ length: maxNumber }, (_, index) => index + 1);
  },

  removeArrayDuplicates(array) {

    return [...new Set(array)];
  },

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  removeUndefined(obj) {
    for (let k in obj) if (obj[k] === undefined) delete obj[k];
    return obj;
  },

  removeObjectDuplicates (array, key) {
    const seen = new Set();
    return array.filter(item => {
      const duplicate = seen.has(item[key]);
      seen.add(item[key]);
      return !duplicate;
    });
  },

  addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  },

  minusDays(date, days) {
    date.setDate(date.getDate() - days);
    return date;
  },

  sortThaiDictionary(list, fieldName) {
    const newList = [...list]
    newList.sort((a, b) => a[fieldName].localeCompare(b[fieldName], 'th'))
    return newList
  },

  localDateTime(d) {
    const localDateStirng =
      [
        d.getFullYear(),
        this.addZero(d.getMonth() + 1),
        this.addZero(d.getDate()),
      ].join("-") +
      "T" +
      [this.addZero(d.getHours()), this.addZero(d.getMinutes())].join(":");

    return localDateStirng;
  },


  roundTime(time, down = true) {
    const roundTo = 15 // minutes
    const roundDownTime = roundTo * 60 * 1000

    return down
      ? time - time % roundDownTime
      : time + (roundDownTime - (time % roundDownTime))
  },



  removeDoubleQuotes(text) {

    return text.replace(/['"]+/g, '');
  },

  between(value, first, last) {

    let lower = Math.min(first, last), upper = Math.max(first, last);
    return value >= lower && value <= upper;
  },


  getShift(time, shifts) {

    // Parse the input time string into a Date object
    const inputTime = new Date(`1970-01-01T${time}Z`);
  
    // Function to parse time string into a Date object
    const parseTime = (timeStr) => new Date(`1970-01-01T${timeStr}Z`);
   
    // Iterate over the shifts to find the matching shift
    for (const shift of shifts) {
      const startTime = parseTime(shift.startTime);
      const endTime = parseTime(shift.endTime);
           

      // Check if the input time falls within the shift interval
      if (startTime > endTime) {
        // Handle the shift that spans across midnight
        if (inputTime >= startTime || inputTime <= endTime) {
          return shift;
        }
      } else {
        // Handle the normal shift
        if (inputTime >= startTime && inputTime <= endTime) {
          return shift;
        }
      }
      
    }

    // If no shift matches, return null or an appropriate value
    return null;

  },

  formatDate(date) {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString('es-CL', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    });
  },



}

export { utils }